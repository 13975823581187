<template>
  <b-sidebar
    sidebar-class="sidebar-lg"
    :visible="$store.state['formSidebar'].componentSidebarEdit"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @change="enableSidebarUpdate"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Editar Oferta</h5>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </div>
      <b-form class="p-2" @submit.prevent="editOffer">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre" label-class="text-left">
              <b-form-input
                id="name"
                type="text"
                v-model.trim="offer.name"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Descripción" label-class="text-left">
              <b-form-textarea
                rows="3"
                max-rows="8"
                v-model.trim="offer.description"
              ></b-form-textarea>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Fecha inicio publicación"
              label-class="text-left"
            >
              <flat-pickr v-model="offer.startDate" class="form-control" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Fecha fin publicación" label-class="text-left">
              <flat-pickr v-model="offer.endDate" class="form-control" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Descuento" label-class="text-left">
              <b-input-group prepend="$" class="mb-2 mr-sm-2 mb-sm-0">
                <b-form-input
                  id="price"
                  type="number"
                  v-model="offer.discountAmount"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Duración" label-class="text-left">
              <b-input-group append="mes(es)">
                <b-form-input
                  type="number"
                  v-model="offer.durationMonths"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button type="submit" variant="primary">Guardar</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
  </b-sidebar>
</template>
<script>
import Container from "../FormContainer";
import useHandlerSidebar from "../useHandlerSidebar";
import flatPickr from "vue-flatpickr-component";
import useToastNotification from "@notification";
import store from "@/store";
import { StatusNotification } from "@/constants";
import actionName from "@actionName";
import {
  BFormTextarea,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BForm,
  BButton,
  BRow,
  BCol,
  BSidebar,
} from "bootstrap-vue";
export default {
  components: {
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
    BSidebar,
    Container,
    flatPickr,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const notification = useToastNotification();
    const {
      subscription: {
        offer: { updateOffer: updateOffer },
      },
    } = actionName;
    const { enableSidebarUpdate } = useHandlerSidebar();
    const editOffer = async () => {
      try {
        await store.dispatch(updateOffer, props.offer);
        notification.dispatchNotification(
          "Oferta",
          StatusNotification.SUCCESS,
          "Oferta actualizada"
        );
      } catch (error) {
        notification.dispatchNotification(
          "Oferta",
          StatusNotification.ERROR,
          "No se pudo realizar la actualización"
        );
      }
    };

    return {
      editOffer,
      enableSidebarUpdate,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>