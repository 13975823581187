<template>
  <container
    :error="error"
    title="Mantenimiento de ofertas"
    titleCreateButton="Nueva Oferta"
  >
    <template #body>
      <b-table
        :fields="columns"
        :items="offers"
        :busy="isDataLoading"
        responsive
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(actions)="row">
          <feather-icon
            icon="EditIcon"
            class="cursor-pointer mr-1"
            size="20"
            @click="handleFormClick(row.item)"
          />
          <feather-icon
            icon="TrashIcon"
            class="cursor-pointer mr-1"
            size="20"
          />
        </template>
      </b-table>
    </template>
    <template #sidebar>
      <create-offer />
      <edit-offer :offer="offer" />
    </template>
  </container>
</template>
<script>
import Container from "../Container.vue";
import useOffer from "./useOffer.js";
import CreateOffer from "./Create.vue";
import EditOffer from "./Edit.vue";
import {
  BBadge,
  BSpinner,
  BTable,
  BAlert,
  BCard,
  BCardText,
  BButton,
} from "bootstrap-vue";
export default {
  components: {
    BBadge,
    BSpinner,
    BTable,
    BAlert,
    BCard,
    BCardText,
    BButton,
    Container,
    CreateOffer,
    EditOffer,
  },
  setup() {
    const { offer, error, isDataLoading, columns, offers, handleFormClick } =
      useOffer();
    return {
      offer,
      error,
      isDataLoading,
      columns,
      offers,
      handleFormClick,
    };
  },
};
</script>