import { ref } from "@vue/composition-api";
import store from "@/store";
import actionName from "@actionName";
import useToastNotification from "@notification";
import { StatusNotification } from "@/constants";

export default function useCreateOption() {
  const notification = useToastNotification();
  const offer = ref({
    name: null,
    description: null,
    startDate: null,
    endDate: null,
    discountAmount: null,
    durationMonths: null
  });
  const {
    subscription: {
      offer: { storeOffer: storeOffer },
    },
  } = actionName;

  const createOffer = async () => {
    try {
      await store.dispatch(storeOffer, offer.value);
      notification.dispatchNotification(
        "Oferta",
        StatusNotification.SUCCESS,
        "Oferta registrada"
      );
    } catch (error) {
      notification.dispatchNotification(
        "Oferta",
        StatusNotification.ERROR,
        "No se pudo completar el registro"
      );
    }
  };

  return {
    offer,
    createOffer,
  };
}
