import { ref, provide, onBeforeMount } from "@vue/composition-api";
import actionName from "@actionName";
import store from "@/store";
import useHandlerSidebar from "../useHandlerSidebar";

export default function useOffer() {
  const blankOffer = ref({
    name: "",
    description: "",
    startDate: null,
    endDate: null,
    discountAmount: null,
    durationMonths: null
  });
  const offer = ref(JSON.parse(JSON.stringify(blankOffer)));

  const defaultOptionDate = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const columns = [
    {
      key: "name",
      label: "Nombre",
    },
    {
      key: "startDate",
      label: "Fecha inicio publicación",
      formatter: (value) => {
        if (!value) return "";
        return new Date(value).toLocaleDateString("es", defaultOptionDate);
      },
    },
    {
      key: "endDate",
      label: "Fecha fin publicación",
      formatter: (value) => {
        if (!value) return "";
        return new Date(value).toLocaleDateString("es", defaultOptionDate);
      },
    },
    {
      key: "description",
      label: "Descripción",
    },
    {
      key: "discountAmount",
      label: "Valor Descuento",
    },
    {
      key: "discountPercentage",
      label: "Porcentaje Descuento",
    },
    {
      key: "durationMonths",
      label: "Duración (meses)",
    },
    {
      key: "actions",
      label: "Acciones",
    },
  ];
  const offers = ref([]);
  const error = ref("");
  const isDataLoading = ref(true);
  const {
    subscription: {
      offer: { getAll: getAllOffers },
    },
  } = actionName;
  const { enableSidebarUpdate } = useHandlerSidebar();
  const handleFormClick = (offerData) => {
    enableSidebarUpdate(true);
    offer.value = offerData;
  };

  const _getAllOffers = async () => {
    try {
      offers.value = await store.dispatch(getAllOffers);
    } catch (err) {
      error.value = err.message;
    } finally {
      isDataLoading.value = false;
    }
  };

//   const onSearch = (query) => {
//     console.log("Buscando -> " + query);
//   };
  provide("onSearch", onSearch);

  onBeforeMount(async () => {
    await _getAllOffers();
  });

  return {
    offer,
    error,
    isDataLoading,
    columns,
    offers,
    handleFormClick,
  };
}
